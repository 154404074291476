.container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-gap: 20px;
  /*justify-content: center;*/
}

.widgets_container {
  grid-column: span 2;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
}

.quick_access_container {
}

.account_info_container {
  grid-row: span 2;
}

.learn_container {
}

.invitation_container {
  grid-column: span 2;
}

.recent_container {
  grid-column: span 2;
}

.active_broadcasts_container {
  grid-column: span 2;
}

.container .quick_access_container + .account_info_container {
  grid-template-columns: 1fr 1fr;
}

ul.plain-list {
  padding: 0 0 0 0px;
  margin: 0;
  list-style-type: none;
}

ul.bullet-list {
  padding: 0 0 0 16px;
  margin: 0;
  list-style-type: disc;
}

ul.plain-list li:not(:first-child),
ul.bullet-list li:not(:first-child) {
  margin-top: 12px
}

ul.plain-list li::marker,
ul.bullet-list li::marker{
  color: var(--form-divider-text-color);
}

.greeting-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*background: linear-gradient(to bottom, #f8f9fa, #e8eaed);*/
  padding: 10px;
  padding-bottom: 20px;
}

.greeting {
  font-size: 15pt;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: #3c4043;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  background: linear-gradient(to right, rgb(79, 130, 238), rgb(163, 113, 193), rgb(195, 94, 111));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

/*.greeting {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  margin-bottom: 1rem;*/
/*  color: var(--form-text-color);*/
/*  font-size: 15pt;*/
/*}*/

@media screen and (max-width: 600px) {
  .container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    flex-direction: column;
  }
}




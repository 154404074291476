:root {
  --pie-chart-background-color: var(--form-control-background-color); /*#d3d3d3;*/
  --pie-chart-pie-color: var(--form-control-active-background-color); /*#b5b5b5;*/
  --pie-chart-overflow-color: #e58041;
  --pie-chart-overflow-background-color: #e29c3d;
  --pie-chart-text-color: var(--label-color);
  --pie-chart-text-muted-color: var(--caption-color);

  --percentage-background-color: #3e3e3e;
  --percentage-border-color: #3e3e3e;
  --percentage-bar-background-color: #39614c;
  --percentage-bar-text-color: #ccc;
}

/*[data-theme="dark"] {*/
/*  --pie-chart-pie-color: #454545;*/
/*  --pie-chart-background-color: #353535;*/
/*  --pie-chart-background-color: var(--form-control-background-color); !*#d3d3d3;*!*/
/*  --pie-chart-pie-color: var(--form-control-active-background-color); !*#b5b5b5;*!*/
/*  --pie-chart-overflow-color: #6a2121;*/
/*  --pie-chart-overflow-background-color: #943d15;*/
/*  --pie-chart-text-color: #fff;*/
/*  --pie-chart-text-muted-color: #aaa;*/

/*  --percentage-background-color: #3e3e3e;*/
/*  --percentage-border-color: #3e3e3e;*/
/*  --percentage-bar-background-color: #474747; !*#39614c;*!*/
/*  --percentage-bar-border-color: #39614c;*/
/*  --percentage-bar-text-color: #ccc;*/
/*}*/

.percentage {
  width: 100%;
  border-radius: 3px;
  position: relative;
  height: 16px;
  padding: 1px;
}

.percentage-sm {
  width: 30px;
  height: 10px;
}

.percentage-md {
  width: 40px;
  height: 16px;
}

.percentage-lg {
  width: 60px;
  height: 22px;
}

.percentage-border {
  border: 1px solid var(--percentage-border-color);
}

.percentage-bar {
  background-color: var(--percentage-bar-background-color);
  height: 100%;
  border-radius: 2px;
}

.percentage-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--percentage-bar-text-color);
  font-size: 6pt;
}

.percentage-sm .percentage-text {
  font-size: 4pt;
}

.percentage-md .percentage-text {
  font-size: 6pt;
}

.percentage-lg .percentage-text {
  font-size: 10pt;
}




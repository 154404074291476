.container {
  display: none;
}

.header {

}

.body {

}

table.body {

}

table.body tr.header td {
  font-weight: 700;
  color: #888;
  letter-spacing: 0.10em;
  text-transform: uppercase;
  padding: 8px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

table.body tr td {
  padding: 8px;
}

.footer {

}

.text {
  font-style: italic;
}

.label {
  font-weight: 700;
  color: #aaa;
  letter-spacing: 0.10em;
  text-transform: uppercase;
}

.title-borders {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-top: 1px solid #ccc;
  border-bottom: 1px double #ccc;
}

.total-borders {
  border-top: 1px solid #ccc;
  border-bottom: 4px double #ccc;
}

@media print {
  .container {
    display: block;
  }
}
